import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import 'styles/main.scss';
import './AllTipsPage.scss';

import Layout from 'components/Layout';
import Banner from 'components/common/Banner';
import Tips from 'components/common/Tips';
import DidYouKnowLoadableWrapper from 'components/FooterPromo/DidYouKnowLoadable';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

const appendTipsBlockByPopularProduct = (tipsBlocks, recommendedProductsList) => {
  tipsBlocks.forEach((block) => {
    const foundProduct = recommendedProductsList.find(
      (product) => block.properties.popularProductLink[0].url === product.link
    );
    block.properties.popularProductData = { ...foundProduct };
  });
};

const AllTipsPage: FC<AllTipsContent.Page> = ({
  data: {
    umbracoTips: data,
    recommendedProducts: { nodes: recommendedProductsList },
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const {
    name,
    subMenu,
    didYouKnow,
    image,
    imageAlt,
    tipsBlocks,
    seoMetaKeywords,
    seoMetaTitle,
    seoMetaDescription,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    submenuAriaLabel,
    link,
  } = data;

  appendTipsBlockByPopularProduct(tipsBlocks, recommendedProductsList);

  return (
    <Layout
      subMenu={{
        links: subMenu,
        ariaLabel: submenuAriaLabel,
      }}
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <div className="dt-all-tips-page">
        <Banner title={{ boldText: name }} img={image} alt={imageAlt} />
        <Container fluid>
          {tipsBlocks.map(
            (
              {
                properties: {
                  titleBold,
                  titleRegular,
                  switchTitles,
                  tagNodes,
                  seeAllButtonTitle,
                  seeAllButtonLink,
                  popularProductTitle,
                  popularProductLink,
                  popularProductData: {
                    recognisableFeature,
                    shortNameBold,
                    shortNameRegular,
                    localHeroImage,
                    cardImage,
                    productColor,
                    name: productName,
                  },
                },
              },
              index
            ) => (
              <div
                key={titleRegular}
                id={subMenu[index].linkToSection}
                className="dt-all-tips-page__frame"
              >
                <Tips
                  title={{
                    regularText: Number(switchTitles) ? titleBold : titleRegular,
                    boldText: Number(switchTitles) ? titleRegular : titleBold,
                  }}
                  data={tagNodes}
                  btn={{
                    btnText: seeAllButtonTitle,
                    btnLink: seeAllButtonLink[0].url,
                  }}
                  popularProductData={{
                    categoryTitle: popularProductTitle,
                    shortNameRegular,
                    shortNameBold,
                    recognisableFeature,
                    link: extractUrlFromMultiPicker(popularProductLink),
                    localHeroImage,
                    name: productName,
                    cardImage,
                    productColor,
                  }}
                />
                <hr className="dt-hr-wide" />
              </div>
            )
          )}
        </Container>
        <DidYouKnowLoadableWrapper didYouKnow={didYouKnow} mask />
      </div>
    </Layout>
  );
};

export default AllTipsPage;

export const query = graphql`
  query AllTipsPageQuery($popularProductLinks: [String], $lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    umbracoTips(lang: { eq: $lang }) {
      name
      subMenu {
        linkToSection
        name
      }
      didYouKnow {
        labelText
        descriptionText
        buttonText
        ariaLabel
        imageAlt
        didYouKnowBG {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        buttonURL {
          url
          icon
        }
      }
      image {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      link
      imageAlt
      seoMetaDescription
      seoCanonicalUrl
      seoImage
      seoExternalHreflangs {
        key
        value
      }
      tipsBlocks {
        properties {
          imageAlt
          iconImage
          popularProductLink {
            url
            icon
          }
          popularProductTitle
          seeAllButtonTitle
          seeAllButtonLink {
            url
            icon
          }
          tagNodes {
            title
            id
            name
            icon
            link {
              queryString
              url
            }
          }
          titleBold
          titleRegular
          switchTitles
        }
        structure
      }
      submenuAriaLabel
      seoMetaKeywords
      seoMetaTitle
      id
    }
    recommendedProducts: allUmbracoProduct(
      filter: { link: { in: $popularProductLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        link
        recognisableFeature
        shortNameBold
        shortNameRegular
        name
        productColor
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 120) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cardImage {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
    }
  }
`;
